import React from 'react'

const FormRadioList = ({ itemKey, list, currentValue, onChange, wrapperClassName, itemClassName }) => {
  return (
    <div className={`flex gap-4 ${wrapperClassName}`}>
      {list.map(({ label, value }, idx) => (
        <label key={`${itemKey}-${idx}`}>
          <input
            key={`${itemKey}-${idx}`}
            value={idx}
            name={itemKey}
            type="radio"
            className={`mr-1 ${itemClassName}`}
            onChange={onChange}
            checked={currentValue === `${value}`}
          />
          {label}
        </label>
      ))}
    </div>
  )
}

export default FormRadioList
