import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import navImage from '../../../assets/images/nav_ttl.svg'

class HeaderPart extends React.Component {
    constructor() {
        super()
        this.state = {

        }
    }

    componentDidMount() {
    }

    render() {
        let noticeLabel

        if ( window.location.href.indexOf('stg.intra.shiftinc.jp') >= 0 || window.location.href.indexOf('localhost') >= 0 ){
            noticeLabel = <div className="text-white">検証環境</div>
        }
        return (
            <nav className="fixed top-0 bg-black h-12 w-full z-10 px-2 md:px-0">
                <div className="mx-auto max-w-[800px] flex">
                    <div className="nav-icon text-white !py-2" >新卒検定</div>
                    <div className="py-2 ml-auto">
                        {noticeLabel}
                    </div>
                </div>
            </nav>
        )
    }
}

HeaderPart.propTypes = {
    dispatch: PropTypes.func.isRequired,
}

HeaderPart.contextTypes = {
  router: PropTypes.object,
}

function mapStateToProps(state) {
    return {
        examinee_name: state.app.examinee_name
    }
}

export default connect(mapStateToProps)(HeaderPart)
