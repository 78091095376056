import React from 'react'

const FormItemContent = ({ children, wrapperClassName = '', className = '', helperText = null }) => {
  return (
    <div className={`flex flex-col w-full ${wrapperClassName}`}>
      <div className={`flex w-full flex-col justify-start items-start sm:flex-row sm:items-center gap-4 ${className}`}>{children}</div>
      {helperText}
    </div>
  )
}

export default FormItemContent
