import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import HeaderPart from './Header'
import FooterPart from './Footer'
import {confirmExam} from './AppActions'
import {Redirect} from 'react-router'
import WelcomeImage from '../../../assets/images/welcome.png'

class TempPage extends React.Component {
    constructor() {
        super()
        this.state = {
            email: "",
            exam_key: "",
            disabled:false
        }
    }

    setEmail(evt) {
        this.setState({email:evt.target.value});
    }

    setExamKey(evt) {
        this.setState({exam_key:evt.target.value});
    }

    regist(e) {
        e.preventDefault();
        if(!this.state.disabled){
            this.setState({
                disabled:true
            })
            this.props.dispatch(confirmExam(this.state.email, this.state.exam_key))
        }
    }

    render() {
        let {error, errCode,published} = this.props
        if (errCode > 0 && errCode != 3) {
            if(errCode==1){
                return <Redirect to="/finish"/>
            }
            else{
                return <Redirect to="/error"/>
            }
        }
        let hash = this.state.hash_key;
        let {disabled} = this.state
        return (
            <div className="w-full">
                <HeaderPart/>
                <div className="p-0 sm:p-10">
                    <div className="card">
                        <div className="card-header text-center">新卒検定 受検URL発行</div>
                        <div className="card-body mt-10">
                            <br/>
                            { !published
                            ?(<div>{`
    受検にあたり、受検URLの発行を行います。
    指定された検定キーと登録いただいたアドレスを入力してください。メールにて受検情報を送付します。
                                `}
                                <br/>
                                <form className="md:mx-10 mx-2" onSubmit={(e) => this.regist(e)}>
                                    <div className="py-2 flex justify-center">
                                        <input className="grow atama" type="text" name="examId" id="examId" placeholder="検定キーを入力してください" onChange={evt => this.setExamKey(evt)} required/>
                                    </div>
                                    <div className="py-2 flex justify-center">
                                        <input className="grow atama" type="email" name="email" id="email" placeholder="メールアドレスを入力してください" onChange={evt => this.setEmail(evt)} required/>
                                    </div>
                                    <div className="flex justify-center">
                                        <button disabled={disabled} className="btn" type="submit" color="danger">受検URL発行</button>
                                    </div>
                                </form>
                            </div>):
                            (
                                <div>
                                {`
    メールを送付しました。受信ボックスのメールを確認いただき、受検ください。
                                `}
                                </div>
                            )
                            }
                        </div>
                    </div>
                </div>

                <FooterPart/>
            </div>
        )
    }
}

TempPage.propTypes = {
    dispatch: PropTypes.func.isRequired,
}

TempPage.contextTypes = {
  router: PropTypes.object,
}

function mapStateToProps(state) {
    return {
        email: state.app.email,
        published:state.app.published,
        error: state.app.error,
        errCode: state.app.errCode
    }
}

export default connect(mapStateToProps)(TempPage)
